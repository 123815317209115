import React from "react"
import { Script } from "gatsby"
import { Typography, Container } from "@mui/material"
import { Box } from "@mui/system"

// import { FlexRow } from "../components/containers/flexRow"
// ScheduleOnce embed START
function Booking() {
  return (
    <Container maxWidth="false" sx={{ maxWidth: "90%", margin: "0 auto" }}>
      <Typography variant="h1" sx={{ textAlign: "center" }}>
        Book a 1-1 call with Anthony
      </Typography>
      <Typography variant="body1" sx={{ textAlign: "center" }}>
        Book either a 15 or 30 minute 1-1. For a follow up consultation book a
        60 minute call.
      </Typography>
      <Box
        sx={{ paddingBlock: "1.75em" }}
        id="SOIDIV_AnthonyChurch"
        data-so-page="AnthonyChurch"
        data-height="550"
        data-style="border: 1px solid #d8d8d8; min-width: 290px; max-width: 900px;"
        data-psz="00"
      ></Box>
      <Script
        type="text/javascript"
        src="https://cdn.oncehub.com/mergedjs/so.js"
      ></Script>
    </Container>
  )
}

export default Booking
